<template>
  <div class="page-sub-header">
    <div class="sub-header-left">

      <!-- responsive toggle -->
      <button @click="toggleBodyClass" class="toggle-btn">
        <img src="../assets/img/icon-bars.svg" alt="" />
      </button>
      <!-- responsive toggle -->

      <!-- back to listing button -->
      <button class="btn custom-btn pattern-btn" v-if="showBackButton" @click="backToMain()">
        <span><svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5 9.5L1 5.5L5 1.5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        Back</span>
      </button>
      <!-- back to listing button -->

      <!-- <h1>{{ $route.name }}</h1> -->

       <!-- page title -->
       <h1>{{ pageTitle }}</h1>
      <!-- page title -->

    </div>
    <div v-if="user" class="company-name">
      <span>
        <img src="../assets/img/icon-user.svg" alt="" />
      </span>
      <p>
        <b>{{ user.name }}</b>
        <label>{{ user.email }}</label>
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  methods: {
    toggleBodyClass() {
      document.body.classList.toggle('sidebar-active');
    },
    backToMain() { 
      this.$router.back();
     }

  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
    }),

    // back to main button
    showBackButton() {
      const backButtonRoutes = [
        'Part Settings',
        'Add Vehicle',
        'Edit Vehicle',
        'Bulk Car Parts',
        'Print All Barcode',
        'Add Parts'
      ];
      return backButtonRoutes.includes(this.$route.name);
    },
     pageTitle() {
      const titles = {
        'Car list': 'Add Vehicle & Parts',
        'Part list': 'Add Vehicle & Individual Parts',
        'Checkout': 'Checkout Parts'
      };
      return titles[this.$route.name] || this.$route.name;
    }
  }
};
</script>

<style scoped></style>